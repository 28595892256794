/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import checkMarkWhiteIcon from 'assets/icons/checkmark-white.svg';
import {
  SearchBox,
  connectInfiniteHits,
  connectStateResults,
  connectHits,
} from 'react-instantsearch-dom';
import { get, isEmpty } from 'lodash';
import avo from 'analytics/avo';

import isClient from 'commons/isClient';
import CustomerReviewCard from 'components/consumer/customer-reviews-list/CustomerReviewCard';
import ProductCatalogCard from 'components/consumer/associate/ProductCatalogCard';
import ExpandableItemsList from 'components/consumer/ExpandableItemsList';
import JBImage from 'components/essentials/JBImage';
import Checkbox from 'components/consumer/Checkbox';

import { SelectedFilter } from 'components/consumer/inspiration-photos-grid/search-state/utils';
import IconSearch from 'components/icons/IconSearch';
import {
  ALGOLIA_INDEX_NAME,
  COLOR_CODES_FABRIC,
  MATERIAL_CODES,
} from 'commons/constants';
import useScreen from 'hooks/useScreen';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import useStaticProductListingPageData from 'hooks/useStaticProductListingPageData';
import useProductVariantsToShow from 'hooks/useProductListingData/useProductVariantsToShow';
import ProductCardPriceUI from 'components/consumer/product-card/ProductCardPrice/ProductCardPriceUI';

import useProjectSimonConfig from 'data-hooks/useProjectSimonConfig';
import { productHitsOptionColorMatch as productHitsOptionColorMatchStatic } from '../instant-search/components/StaticProductResults';

let productTypes = [];
let ALL_OPTIONS_BY_COLOR = null;

const getAllOptionsByColor = (allOptionsValues = {}) => {
  if (!ALL_OPTIONS_BY_COLOR && !isEmpty(allOptionsValues)) {
    ALL_OPTIONS_BY_COLOR = {};
    Object.keys(allOptionsValues || {}).forEach(val => {
      const optionValue = allOptionsValues[val];
      if (optionValue.color) {
        if (!ALL_OPTIONS_BY_COLOR[optionValue.color]) {
          ALL_OPTIONS_BY_COLOR[optionValue.color] = [];
        }
        ALL_OPTIONS_BY_COLOR[optionValue.color].push(optionValue);
      }
    });
  }
  return ALL_OPTIONS_BY_COLOR;
};

export const productIndexes = [
  ALGOLIA_INDEX_NAME.PRODUCTS,
  ALGOLIA_INDEX_NAME.PRODUCTS_PRICE_ASC,
  ALGOLIA_INDEX_NAME.PRODUCTS_PRICE_DESC,
];
export const CATEGORY_INDEX_MAPPING = {
  products: ALGOLIA_INDEX_NAME.PRODUCTS,
  categories: 'categories',
  pages: ALGOLIA_INDEX_NAME.STATIC_PAGES,
  reviews: 'reviews',
  'customer photos': 'customer_photos',
};

const ALGOLIA_INDEX_CATEGORY_MAPPING = {
  [ALGOLIA_INDEX_NAME.PRODUCTS]: 'products',
  [ALGOLIA_INDEX_NAME.STATIC_PAGES]: 'pages',
  [ALGOLIA_INDEX_NAME.CUSTOMER_PHOTOS]: 'customer photos',
  [ALGOLIA_INDEX_NAME.REVIEWS]: 'reviews',
  [ALGOLIA_INDEX_NAME.CATEGORIES]: 'categories',
};

const productHitsOptionColorMatch = productHits => {
  if (!productHits?.length) {
    return null;
  }
  let matchedColorCode = null;
  const firstHit = productHits[0];
  if (firstHit?._highlightResult?.option_color) {
    const { option_color } = firstHit._highlightResult;
    const optionColorEntries = firstHit.option_color || [];
    let colorIdx = 0;

    for (const optColor of option_color) {
      if (optColor?.matchedWords?.length) {
        if (
          (optionColorEntries[colorIdx] || '').toLowerCase().trim() ===
          optColor.matchedWords[0].toLowerCase().trim()
        ) {
          matchedColorCode = optionColorEntries[colorIdx];
          break;
        }
      }
      colorIdx += 1;
    }
  }
  return matchedColorCode;
};

const getOverridesProps = (productHits, allOptionsValues, colorFiltered) => {
  const allOptionsByColor = allOptionsValues
    ? getAllOptionsByColor(allOptionsValues)
    : null;

  const productHitsOptionQueryMatch = productHitsOptionColorMatch(productHits);

  if (!colorFiltered?.currentColor?.length && !productHitsOptionQueryMatch) {
    return {};
  }

  const finalAllOptionsByColor = colorFiltered?.currentColor?.length
    ? colorFiltered.currentColor[0]
    : productHitsOptionQueryMatch;

  return productHits.reduce((acc, hit) => {
    const hitOptionValue =
      allOptionsByColor?.[finalAllOptionsByColor]?.find(colorOpt =>
        hit.option_value?.includes(colorOpt.value)
      )?.value ?? '';

    const optionName = [
      'Fabric',
      'Leather',
      'Wood',
      'Wood Stain',
      'Frame',
    ].includes(hit.default_material_type)
      ? hit.default_material_type
      : '';

    return {
      ...acc,
      [hit.product_id]: {
        forMaterial: hitOptionValue,
        forMaterialType: optionName,
      },
    };
  }, {});
};

const sendData = (query, productIDs, productSkus, otherProps) => {
  if (!productIDs || !productIDs.length) {
    return;
  }
  /*
 get top 3 results and send them to analytics .
 Send only once per query as this part of the code gets called several times.
 To do that ,check with query if it has changed .
 Sometimes query remains same but the search results change.
 in both cases,send data to analytics.
 In this function,we are also setting the last query and top 3 results to the state as well.
 These state values help us identify if query changed or result changed etc.
*/
  const topThreeRes = productIDs.slice(0, 3);

  if (query !== '' && otherProps.lastQuery !== query) {
    otherProps.setLastQuery(query);
    otherProps.setLastQueryTopThreeRes(topThreeRes);
  } else if (
    otherProps.lastQuery === query &&
    JSON.stringify(otherProps.lastQueryTopThreeRes) !==
      JSON.stringify(topThreeRes)
  ) {
    otherProps.setLastQueryTopThreeRes(topThreeRes);
  }

  otherProps.setInactive(false);
};

const getBlogUrl = url => {
  const blogPost = url
    .split('/')
    .filter(v => !!v)
    .pop();
  return `https://joybird.com/blog/${blogPost}`;
};

export const getAdvancedSearchUrlForQueryAndIndex = (
  query = '',
  index = 'products_jb4',
  facetVal
) =>
  `/search/?category=${encodeURIComponent(
    index.indexOf('products') !== -1 ? 'products_jb4' : index
  )}&q=${encodeURIComponent(query) || ''}&filter=${encodeURIComponent(
    facetVal || '*'
  )}`;

const ResultsHeader = ({ variant = 'desktop', children }) => (
  <div
    className={classNames('mx-0 my-2.5 text-base font-bold', {
      'hidden lg:block': variant === 'advanced-search',
      'text-gray': variant !== 'mobile',
      'text-brand block mt-[15px] mb-[7px]': variant === 'mobile',
    })}
  >
    {children}
  </div>
);

ResultsHeader.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const ProductResultsMobile = connectStateResults(
  ({
    searchState,
    searchResults,
    refine,
    isInstantSearch,
    layout,
    searching,
    allOptionsData = {},
    ...otherProps
  }) => {
    const { hits } = isInstantSearch ? searchResults : otherProps;
    const productIDs = hits.map(hit => parseInt(hit.product_id, 10));
    const productSkus = hits.map(hit => hit.serial_number);
    if (isInstantSearch && !productIDs.length) {
      return null;
    }
    const currentPage = useUserCurrentPageType();

    // CN-533 Search data to GTM
    if (otherProps.inactive) {
      sendData(searchState.query, productIDs, productSkus, otherProps);
    }

    const overrideProps = useMemo(
      () => getOverridesProps(hits, allOptionsData?.allOptionsValues, null),
      [hits, allOptionsData?.allOptionsValues]
    );

    const colorFacetQuery = productHitsOptionColorMatchStatic(hits);

    const instantSearchStaticData = useStaticProductListingPageData(
      {
        productIds: productIDs,
      },
      {
        onlyShowValidFilters: true,
      }
    );

    const productsWithColoredVariants = useProductVariantsToShow({
      colorFilters: colorFacetQuery?.length > 0 ? colorFacetQuery : null,
      products: instantSearchStaticData.products,
    });

    const instantSearchProductsData = useMemo(
      () =>
        colorFacetQuery?.length > 0
          ? productsWithColoredVariants.slice(0, 3)
          : instantSearchStaticData?.products?.slice(0, 3) ?? [],
      [colorFacetQuery, productsWithColoredVariants, instantSearchStaticData]
    );

    return (
      <div className="instant-search-form__product-results flex-1 [border-right:1px_solid_#f1f1f1] pt-5 pr-8 pb-10 [&_.ais-Hits-list]:flex [&_.ais-Hits-item]:p-2.5">
        <ResultsHeader variant="mobile">
          {otherProps.forComponent === 'mobile' ? 'Results' : 'Products'}
        </ResultsHeader>
        {instantSearchProductsData?.map(product => (
          <Link
            to={`${product.variants?.[0].path ?? ''}`}
            className="text-base flex items-center font-bold text-gray px-0 py-4 [border-bottom:1px_solid_#f1f1f1] no-underline last:[border-bottom:0] [&_img]:object-contain [text-decoration:none]"
            key={product.id}
            onClick={() => {
              avo.productsSearched({
                query: searchState.query,
                location: currentPage,
                searchTarget: avo.SearchTarget.PRODUCT_GRID,
              });

              otherProps.toggleMenu?.();
            }}
          >
            <div className="mr-4 w-[100px] flex justify-center items-center">
              <JBImage
                className="object-contain m-5 opacity-100 max-h-full max-w-full"
                fit="clip"
                height={265}
                width={265}
                lazy={false}
                loader={false}
                src={`${product.variants?.[0].image ?? ''}`}
                trim="color"
                trimPad={20}
                {...overrideProps[product.id]}
              />
            </div>
            <div>
              {product.name}
              <ProductCardPriceUI
                originalPrice={product.variants?.[0].originalPrice}
                salePrice={product.variants?.[0].price}
              />
            </div>
          </Link>
        ))}
        {isInstantSearch && (
          <div className="flex mt-5 justify-start [&_a]:[text-decoration:none]">
            <SeeAllButton
              toggleSearchOpen={otherProps.toggleSearchOpen}
              variant="mobile"
            />
          </div>
        )}
      </div>
    );
  }
);

export const StoreCatalogProductResults = connectInfiniteHits((props = {}) => {
  const { windowWidth } = useScreen();
  const infiniteScrollThreshold = windowWidth <= 768 ? '2800px' : '2200px';

  const { hits, hasMore, onLoadMore } = props;
  const productsList =
    hits && hits.length > 0
      ? hits.map(hit => <ProductCatalogCard product={hit} />)
      : [];

  return (
    <div className="catalog-search-results">
      <div className="text-sm grid [border-bottom:1px_solid_#e5e5e5] mt-8 mr-0 mb-4 ml-8 font-normal w-full grid-cols-[29rem_1fr_1fr_1fr] max-[1023px]:hidden">
        <div className="catalog-search-results__header-product">Active</div>
        <div>SKU</div>
        <div>Shipping(Custom)</div>
        <div>Stock</div>
      </div>
      <div className="flex w-full overflow-hidden [&_.infinite-scroll-component]:!overflow-hidden [&_.infinite-scroll-component]:w-full [&>div]:w-full">
        <InfiniteScroll
          dataLength={productsList.length}
          next={onLoadMore}
          hasMore={hasMore}
          scrollThreshold={infiniteScrollThreshold}
          loader="Loading..."
        >
          {productsList}
        </InfiniteScroll>
      </div>
    </div>
  );
});

export const ReviewsResults = connectInfiniteHits(
  ({ hits, hasMore, refine, variant = 'advanced-search', ...otherProps }) => {
    const { windowWidth } = useScreen();
    const infiniteScrollThreshold = windowWidth <= 768 ? '2800px' : '2200px';

    const reviewsList = hits.map(hit => (
      <CustomerReviewCard
        key={hit.review_id}
        productReview={hit}
        type="search"
        variant="advanced-search"
      />
    ));

    return (
      <>
        <ResultsHeader variant={variant}>
          {otherProps.forComponent === 'mobile' ? 'Results' : 'Reviews'}
        </ResultsHeader>

        <InfiniteScroll
          dataLength={reviewsList.length}
          next={otherProps.onLoadMore}
          hasMore={hasMore}
          scrollThreshold={infiniteScrollThreshold}
          loader={<h4>Loading...</h4>}
        >
          {reviewsList}
        </InfiniteScroll>
      </>
    );
  }
);

export const PagesResults = connectInfiniteHits(
  ({ hits, hasMore, refine, variant = 'desktop', ...otherProps }) => {
    const { windowWidth } = useScreen();
    const infiniteScrollThreshold = windowWidth <= 768 ? '2800px' : '2200px';

    const { data: projectSimonConfig } = useProjectSimonConfig();
    const {
      isProjectSimonEnabled,
      projectSimonFabricName,
    } = projectSimonConfig;

    const shouldFilterProjectSimon =
      !isProjectSimonEnabled && !!projectSimonFabricName;

    const pagesList = hits
      .filter(hit => {
        if (!shouldFilterProjectSimon) {
          return true;
        }

        return !['title', 'name', 'meta_description', 'description'].some(key =>
          hit?.[key]
            ?.toLowerCase()
            ?.includes(projectSimonFabricName?.toLowerCase())
        );
      })
      .map(hit => {
        if (!hit?.url) {
          return null;
        }
        let finalURL = (
          <Link
            onClick={async () => {
              if (otherProps.isInstantSearch) {
                await otherProps.toggleSearchOpen();
              }
              avo.productsSearched({
                query: otherProps?.query ?? '',
                location: otherProps?.currentPage,
                searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
              });
            }}
            to={(hit.url || '').startsWith('/') ? hit.url : `/${hit.url}`}
          >
            {hit.url}
          </Link>
        );
        if (hit.url && hit.url.startsWith('/stories')) {
          finalURL = (
            <a
              onClick={async () => {
                if (otherProps.isInstantSearch) {
                  await otherProps.toggleSearchOpen();
                }
                avo.productsSearched({
                  query: otherProps?.query ?? '',
                  location: otherProps?.currentPage,
                  searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                });
              }}
              href={getBlogUrl(hit.url)}
            >
              {getBlogUrl(hit.url)}
            </a>
          );
        }
        return (
          <div className="flex flex-col bg-gray-light8 p-8 mb-8 text-gray">
            <div className="text-[1.2rem] leading-[2rem] no-underlinetracking-[-.02em] mt-0 mx-0 mb-[1.1rem] font-normal text-gray">
              {hit.title || hit.name}
            </div>
            <div className="font-normal text-gray text-base leading-[1.5rem] flex flex-col items-start text-[.8rem]">
              {hit.meta_description || hit.description}
            </div>
            <div className="no-underline text-[.8rem] cursor-pointer [&_a]:text-brand">
              {finalURL}
            </div>
          </div>
        );
      })
      .filter(Boolean);

    if (otherProps.isInstantSearch) {
      return (
        <div className="instant-search-form__pages-results [border-right:1px_solid_#f1f1f1] pt-5 pr-8 pb-10">
          <ResultsHeader variant={variant}>
            {otherProps.forComponent === 'mobile' ? 'Results' : 'Pages'}
          </ResultsHeader>
          {pagesList.slice(0, 3)}
        </div>
      );
    }

    return (
      <>
        <ResultsHeader variant={variant}>
          {otherProps.forComponent === 'mobile' ? 'Results' : 'Pages'}
        </ResultsHeader>

        <InfiniteScroll
          dataLength={pagesList.length}
          next={otherProps.onLoadMore}
          hasMore={hasMore}
          scrollThreshold={infiniteScrollThreshold}
          loader={<h4>Loading...</h4>}
        >
          {pagesList}
        </InfiniteScroll>
      </>
    );
  }
);

export const SeeAllButton = connectStateResults(
  ({ searchResults, variant = 'desktop', ...otherProps }) => {
    if (!searchResults || !searchResults.query) {
      return null;
    }
    const currentPage = useUserCurrentPageType();
    return (
      <Link
        onClick={async () => {
          if (otherProps && otherProps.toggleSearchOpen)
            await otherProps.toggleSearchOpen();

          avo.productsSearched({
            query: searchResults.query,
            location: currentPage,
            searchTarget: avo.SearchTarget.FULL_RESULTS,
          });
        }}
        to={getAdvancedSearchUrlForQueryAndIndex(
          searchResults.query,
          'products'
        )}
      >
        <div
          className={classNames(
            'p-2.5 border-solid border-gray cursor-pointer font-bold tracking-[-.0125rem] leading-[1.45rem] text-base bg-gray',
            {
              'text-center w-[200px] h-[50px] border-2 text-white':
                variant === 'desktop',
              'text-left w-auto h-auto pt-0 pr-0 pb-0 pl-2.5 border-0 bg-transparent text-gray mr-5':
                variant === 'mobile',
            }
          )}
        >
          See full results ({searchResults.nbHits})
        </div>
      </Link>
    );
  }
);

export const ResultsByCategory = connectStateResults(
  ({
    searchState,
    searching,
    searchResults,
    error,
    allSearchResults,
    isSearchStalled,
    variant = 'desktop',
    ...otherProps
  }) => {
    if (!searchState.query || searching || isSearchStalled) {
      return null;
    }
    const queryWord =
      searchState.query.charAt(0).toUpperCase() + searchState.query.slice(1);
    const currentPage = useUserCurrentPageType();
    return (
      <>
        <ResultsHeader variant={variant}>View results by:</ResultsHeader>
        <div>
          {allSearchResults?.[ALGOLIA_INDEX_NAME.PRODUCTS] &&
          allSearchResults[ALGOLIA_INDEX_NAME.PRODUCTS].nbHits !== 0 ? (
            <Link
              to={getAdvancedSearchUrlForQueryAndIndex(
                searchState.query,
                'products'
              )}
              onClick={async () => {
                await otherProps.toggleSearchOpen();
                avo.productsSearched({
                  query: searchState.query,
                  location: currentPage,
                  searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                });
              }}
              className={classNames(
                'text-sm font-bold mt-1 mb-1 ml-0 no-underline !leading-[1.5rem] active:bg-gray-light8 focus:bg-gray-light8 hover:bg-gray-light8',
                {
                  'block mr-2.5 text-[#585858]': variant === 'desktop',
                  'text-sm px-2 py-1 rounded-[3px] text-gray font-bold mr-2.5 bg-gray-light9 inline-block':
                    variant === 'mobile',
                }
              )}
            >
              Products ({allSearchResults[ALGOLIA_INDEX_NAME.PRODUCTS].nbHits})
            </Link>
          ) : (
            ''
          )}
          {allSearchResults?.[ALGOLIA_INDEX_NAME.CUSTOMER_PHOTOS] &&
          allSearchResults[ALGOLIA_INDEX_NAME.CUSTOMER_PHOTOS].nbHits !== 0 ? (
            <Link
              to={getAdvancedSearchUrlForQueryAndIndex(
                searchState.query,
                'customer_photos'
              )}
              onClick={async () => {
                await otherProps.toggleSearchOpen();
                avo.productsSearched({
                  query: searchState.query,
                  location: currentPage,
                  searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                });
              }}
              className={classNames(
                'text-sm font-bold mt-1 mb-1 ml-0 no-underline !leading-[1.5rem] active:bg-gray-light8 focus:bg-gray-light8 hover:bg-gray-light8',
                {
                  'block mr-2.5 text-[#585858]': variant === 'desktop',
                  'text-sm px-2 py-1 rounded-[3px] text-gray font-bold mr-2.5 bg-gray-light9 inline-block':
                    variant === 'mobile',
                }
              )}
            >
              {queryWord} in Customer Photos (
              {allSearchResults[ALGOLIA_INDEX_NAME.CUSTOMER_PHOTOS].nbHits})
            </Link>
          ) : (
            ''
          )}

          {allSearchResults?.[ALGOLIA_INDEX_NAME.REVIEWS] &&
          allSearchResults[ALGOLIA_INDEX_NAME.REVIEWS].nbHits !== 0 ? (
            <Link
              to={getAdvancedSearchUrlForQueryAndIndex(
                searchState.query,
                'reviews'
              )}
              onClick={async () => {
                await otherProps.toggleSearchOpen();
                avo.productsSearched({
                  query: searchState.query,
                  location: currentPage,
                  searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                });
              }}
              className={classNames(
                'text-sm font-bold mt-1 mb-1 ml-0 no-underline !leading-[1.5rem] active:bg-gray-light8 focus:bg-gray-light8 hover:bg-gray-light8',
                {
                  'block mr-2.5 text-[#585858]': variant === 'desktop',
                  'text-sm px-2 py-1 rounded-[3px] text-gray font-bold mr-2.5 bg-gray-light9 inline-block':
                    variant === 'mobile',
                }
              )}
            >
              {queryWord} in Reviews (
              {allSearchResults[ALGOLIA_INDEX_NAME.REVIEWS].nbHits})
            </Link>
          ) : (
            ''
          )}
          {allSearchResults?.[ALGOLIA_INDEX_NAME.STATIC_PAGES] &&
          allSearchResults[ALGOLIA_INDEX_NAME.STATIC_PAGES].nbHits !== 0 ? (
            <Link
              to={getAdvancedSearchUrlForQueryAndIndex(
                searchState.query,
                'static_pages_new'
              )}
              onClick={async () => {
                await otherProps.toggleSearchOpen();
                avo.productsSearched({
                  query: searchState.query,
                  location: currentPage,
                  searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                });
              }}
              className={classNames(
                'text-sm font-bold mt-1 mb-1 ml-0 no-underline !leading-[1.5rem] active:bg-gray-light8 focus:bg-gray-light8 hover:bg-gray-light8',
                {
                  'block mr-2.5 text-[#585858]': variant === 'desktop',
                  'text-sm px-2 py-1 rounded-[3px] text-gray font-bold mr-2.5 bg-gray-light9 inline-block':
                    variant === 'mobile',
                }
              )}
            >
              {queryWord} in Pages (
              {allSearchResults[ALGOLIA_INDEX_NAME.STATIC_PAGES].nbHits})
            </Link>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
);

export const SearchResults = connectStateResults(
  ({ searchState, children }) => {
    if (!searchState.query) {
      return null;
    }
    return children;
  }
);

export const ChangeToNextPopulatedIndex = connectStateResults(
  ({ allSearchResults, searching, currentIndex, indexChange }) => {
    if (!allSearchResults || searching || !currentIndex) {
      return null;
    }
    const currentIndexResults = allSearchResults[currentIndex];
    if (currentIndexResults && !currentIndexResults?.nbHits) {
      const indexToChangeTo = Object.values(ALGOLIA_INDEX_NAME).find(
        indexName =>
          indexName !== currentIndex &&
          allSearchResults[indexName]?.hits?.length
      );
      if (indexToChangeTo) {
        indexChange(ALGOLIA_INDEX_CATEGORY_MAPPING[indexToChangeTo]);
      }
    }
    return null;
  }
);

export const SearchCount = connectStateResults(
  ({
    searchResults,
    title,
    indexClick,
    selectedOption,
    invalidProductIdsCount,
    id,
  }) => {
    if (!searchResults || searchResults?.nbHits === 0) {
      return null;
    }
    const finalResultCount =
      title === 'Products' && invalidProductIdsCount
        ? searchResults.nbHits - invalidProductIdsCount
        : searchResults.nbHits;
    const isSelected = selectedOption === id;

    return searchResults ? (
      <div className="px-0 py-1.5 max-[767px]:p-0 hover:text-brand">
        <button
          type="button"
          className={classNames('w-full bg-white text-center lg:text-left', {
            'font-bold': isSelected,
          })}
          onClick={() => {
            if (finalResultCount) indexClick(title.toLowerCase());
          }}
        >
          {`${title} (${finalResultCount})`}
        </button>
      </div>
    ) : (
      ''
    );
  }
);

const StaticPageHit = ({ hits = [], variant = 'desktop', ...otherProps }) => {
  const [query] = useQueryParam('q', StringParam);
  const currentPage = useUserCurrentPageType();

  const { data: projectSimonConfig } = useProjectSimonConfig();
  const { isProjectSimonEnabled, projectSimonFabricName } = projectSimonConfig;

  const shouldFilterProjectSimon =
    !isProjectSimonEnabled && !!projectSimonFabricName;

  if (!hits.length) {
    return null;
  }

  return (
    <div className="[border-right:1px_solid_#f1f1f1] pt-5 pr-8 pb-10 static-pages">
      <ResultsHeader variant={variant}>Pages</ResultsHeader>
      <div>
        {hits
          .filter(hit => {
            if (!shouldFilterProjectSimon) {
              return true;
            }

            return !hit.page
              ?.toLowerCase()
              ?.includes(projectSimonFabricName?.toLowerCase());
          })
          .map(hit => {
            const isStoriesURL = (hit?.url || '').startsWith('/stories');
            const finalURL = isStoriesURL ? getBlogUrl(hit.url) : hit.url;

            if (isStoriesURL) {
              return (
                <div key={hit.objectID}>
                  <a
                    className={classNames(
                      'text-sm font-bold mt-1 mb-1 ml-0 no-underline !leading-[1.5rem] active:bg-gray-light8 focus:bg-gray-light8 hover:bg-gray-light8',
                      {
                        'block mr-2.5 text-[#585858]': variant === 'desktop',
                        'text-sm px-2 py-1 rounded-[3px] text-gray font-bold mr-2.5 bg-gray-light9 inline-block':
                          variant === 'mobile',
                      }
                    )}
                    onClick={async () => {
                      await otherProps.toggleSearchOpen();
                      avo.productsSearched({
                        query,
                        location: currentPage,
                        searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                      });
                    }}
                    href={finalURL}
                  >
                    {hit.page}
                  </a>
                </div>
              );
            }
            return (
              <div key={hit.objectID}>
                <Link
                  className={classNames(
                    'text-sm font-bold mt-1 mb-1 ml-0 no-underline !leading-[1.5rem] active:bg-gray-light8 focus:bg-gray-light8 hover:bg-gray-light8',
                    {
                      'block mr-2.5 text-[#585858]': variant === 'desktop',
                      'text-sm px-2 py-1 rounded-[3px] text-gray font-bold mr-2.5 bg-gray-light9 inline-block':
                        variant === 'mobile',
                    }
                  )}
                  onClick={async () => {
                    await otherProps.toggleSearchOpen();
                    avo.productsSearched({
                      query,
                      location: currentPage,
                      searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                    });
                  }}
                  to={finalURL}
                >
                  {hit.page}
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};
StaticPageHit.propTypes = {
  hits: PropTypes.array,
  variant: PropTypes.string,
};
export const StaticPageHits = connectHits(StaticPageHit);

const CategoryHit = ({ hits, variant = 'desktop', ...otherProps }) => {
  const [query] = useQueryParam('q', StringParam);
  const currentPage = useUserCurrentPageType();

  if (!hits.length) {
    return null;
  }

  return (
    <div
      className={classNames(
        '[border-right:1px_solid_#f1f1f1] pt-5 pr-8 pb-10 min-w-[20%] flex-[0_1_20%] pl-[30px] h-full min-h-[264px]',
        {
          '!p-0 min-h-[auto] mb-2 w-full border-0 order-[-1]':
            variant === 'mobile',
        }
      )}
    >
      <ResultsHeader variant={variant}>Categories</ResultsHeader>
      <div>
        {hits.map(hit => (
          <div key={hit.objectID}>
            <Link
              className={classNames(
                'text-sm font-bold mt-1 mb-1 ml-0 no-underline !leading-[1.5rem] active:bg-gray-light8 focus:bg-gray-light8 hover:bg-gray-light8',
                {
                  'block mr-2.5 text-[#585858]': variant === 'desktop',
                  'text-sm px-2 py-1 rounded-[3px] text-gray font-bold mr-2.5 bg-gray-light9 inline-block':
                    variant === 'mobile',
                }
              )}
              onClick={async () => {
                await otherProps.toggleSearchOpen();

                avo.productsSearched({
                  query,
                  location: currentPage,
                  searchTarget: avo.SearchTarget.OTHER_RESULTS_LINK,
                });
              }}
              to={hit.category_sef_url}
            >
              {hit.category_name}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
CategoryHit.propTypes = {
  hits: PropTypes.array,
  variant: PropTypes.string,
};
export const CategoryHits = connectHits(CategoryHit);

export const JBSearchBox = ({
  advanced,
  autoFocus,
  defaultRefinement,
  forPage,
  index,
  inMobileNav = false,
  isActive,
  placeholder = 'What can we help you find?',
  setInactive,
  variant,
  ...otherProps
}) => {
  const history = useHistory();
  const location = useLocation();
  const currentPage = useUserCurrentPageType();
  const [searchQuery, updateSearchQuery] = useState('');
  const { isMobile } = useScreen();
  // instant-search-form__form_inner
  return (
    <div className="[&_i]:w-[18px] [&_i]:h-[18px] [&_i]:text-[18px] [&_i]:block [&_i]:absolute [&_i]:top-1/2 [&_i]:mt-[-9px] [&_i]:right-10 [&_i]:cursor-pointer [&_i]:[border-right:1px_solid_#dfe0e1] bg-gray-light8">
      <div
        className={classNames(
          'px-0 py-[5px] mx-5 my-0 flex flex-row items-stretch relative box-border min-[1440px]:mx-[3.333vw] min-[1440px]:my-0',
          {
            '!ml-3': inMobileNav,
            '[&_.ais-SearchBox-input]:lg:!pl-0 [&_.ais-SearchBox-input]:lg:!text-center [&_.ais-SearchBox-input]:lg:!indent-[-195px] [&_.ais-SearchBox-input::placeholder]:lg:!text-center [&_.ais-SearchBox-input::placeholder]:lg:!pl-[195px] [&_.ais-SearchBox-input.hasValue]:lg:!indent-0 [&_.ais-SearchBox-input.hasValue::placeholder]:lg:!indent-0':
              variant === 'ConsumerInstantSearch',
            '[&_button.ais-SearchBox-reset]:!block':
              variant === 'ConsumerInstantSearch' && isActive,
          }
        )}
      >
        <IconSearch
          className={classNames('self-center min-w-[16px]', {
            'lg:hidden': variant === 'ConsumerInstantSearch',
          })}
          width={18}
          height={18}
        />{' '}
        <div className="inline-flex w-full pl-2">
          <SearchBox
            translations={{
              placeholder,
            }}
            defaultRefinement={defaultRefinement}
            autoFocus={autoFocus}
            onFocus={event => {
              if (!(advanced || forPage === 'associate')) {
                event.currentTarget.placeholder = placeholder;
              }
            }}
            onBlur={event => {
              if (!isMobile) {
                return;
              }
              if (!isMobile && !event.relatedTarget && setInactive) {
                setInactive(true);
                return;
              }
              event.currentTarget.placeholder = placeholder;

              if (event.currentTarget.value.length === 0) {
                const requiredNode = get(
                  event.currentTarget,
                  'parentNode.parentNode.parentNode.parentNode'
                );
                if (requiredNode) requiredNode.classList.remove('active');
              }

              if (setInactive !== undefined) {
                setInactive(true);
              }
            }}
            onInput={event => {
              if (event.currentTarget.value.length > 0) {
                event.currentTarget.classList.add('hasValue');
              } else {
                event.currentTarget.classList.remove('hasValue');
              }
            }}
            onKeyDown={async e => {
              if (isMobile) {
                updateSearchQuery(e.currentTarget.value);
                if (otherProps.setSearchEventParams) {
                  otherProps.setSearchEventParams({
                    searchQuery: e.currentTarget.value,
                    currentPage,
                  });
                }
                return;
              }
              if (
                forPage !== 'associate' &&
                +e.keyCode === 13 &&
                e.target.value !== '' &&
                isClient()
              ) {
                avo.productsSearched({
                  query: e.target.value,
                  location: currentPage,
                  searchTarget: avo.SearchTarget.FULL_RESULTS,
                });
                const newUrl = getAdvancedSearchUrlForQueryAndIndex(
                  e.target.value,
                  index || 'products_jb4',
                  '*'
                );
                if (location.pathname === '/search/') {
                  window.location.href = newUrl;
                } else {
                  history.push(newUrl, {
                    instantQuery: e.target.value,
                  });
                  otherProps.toggleSearchOpen();
                }
              }
            }}
            onReset={async event => {
              const target = event.currentTarget;

              // Remove hasValue class from input
              const form = target?.closest('.ais-SearchBox-form');
              form
                ?.querySelector('.ais-SearchBox-input')
                ?.classList.remove('hasValue');

              if (!(advanced || forPage === 'associate')) {
                await otherProps.toggleSearchOpen();
                if (isMobile) {
                  setInactive?.();
                  const requiredNode = get(
                    target,
                    'parentNode.parentNode.parentNode'
                  );
                  if (requiredNode) {
                    requiredNode.classList.remove('active');
                  }
                  if (searchQuery && searchQuery.length > 0) {
                    avo.productsSearched({
                      query: searchQuery,
                      location: currentPage,
                      searchTarget: avo.SearchTarget.EXIT_SEARCH,
                    });
                  }
                }
              }
            }}
          />{' '}
        </div>{' '}
      </div>{' '}
      {isMobile && (
        <div
          className={classNames(
            'h-0 w-0 top-[65px] z-[-1] left-0 fixed bg-white [transition:opacity_.3s_ease-out] opacity-0 lg:absolute lg:bg-transparent',
            {
              active: isActive,
              hidden: variant === 'InstantSearchMobile',
              'lg:opacity-100 lg:h-screen lg:w-screen lg:top-[-100px] lg:absolute lg:[transition:opacity_.3s_ease-out]': isActive,
            }
          )}
        />
      )}
    </div>
  );
};

JBSearchBox.propTypes = {
  advanced: PropTypes.bool,
  autoFocus: PropTypes.bool,
  defaultRefinement: PropTypes.string,
  forPage: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inMobileNav: PropTypes.bool,
  isActive: PropTypes.bool,
  placeholder: PropTypes.string,
  setInactive: PropTypes.func,
  variant: PropTypes.oneOf(['ConsumerInstantSearch']),
};

export const ResultHeaderSummary = connectStateResults(
  ({ indexName, searchResults, invalidProductIds = [] }) => {
    if (!searchResults || !searchResults.query) {
      return null;
    }
    const finalResultCount =
      indexName?.includes('products') && invalidProductIds.length
        ? searchResults.nbHits - invalidProductIds.length
        : searchResults.nbHits;
    return (
      <h1
        className={classNames(
          'text-2xl font-bold scale-100 [transform-origin:left_center] [transition:transform_.2s_ease_0s] mb-0 max-[1023px]:text-xl max-[1023px]:font-bold max-[1023px]:mt-5 max-[1023px]:mb-4 max-[1023px]:mx-4 md:flex md:gap-5 md:items-center',
          '[&_span]:text-sm [&_span]:font-normal [&_span]:text-gray-light2'
        )}
      >
        Results for &quot;{searchResults.query}&quot;{' '}
        <span className="text-sm text-gray-light2 md:relative md:self-center font-normal self-center relative">
          {finalResultCount} Results
        </span>
      </h1>
    );
  }
);

export const FurnituresFilter = connectStateResults(
  ({ searchResults, onChange, typeFiltered }) => {
    if (!searchResults || !searchResults._rawResults[0].facets.type) {
      return null;
    }
    productTypes = !typeFiltered.isFiltered
      ? searchResults._rawResults[0].facets.type
      : productTypes;
    return (
      <>
        <div className="text-base font-bold text-gray px-0 py-4">Furniture</div>

        <div className="space-y-2">
          <ExpandableItemsList>
            {Object.keys(productTypes).map(type => (
              <Checkbox
                key={type}
                onClick={() => {
                  onChange([{ label: type }]);
                }}
                checked={type === typeFiltered.currentType}
                id={type}
                size="sm"
              >
                {type}
              </Checkbox>
            ))}
          </ExpandableItemsList>
        </div>
      </>
    );
  }
);

export const OtherFilters = connectStateResults(
  ({
    searchResults,
    onMaterialChange,
    isMaterialFiltered,
    selectedFiltersList,
    clearAllFilters,
    handleRemoveFilter,
  }) => {
    if (!searchResults) {
      return null;
    }
    return (
      <div className="mt-2 text-left lg:mt-0 lg:sticky lg:top-0">
        {selectedFiltersList.length ? (
          <div className="flex flex-row justify-between pb-2 border-b-[1px] border-b-gray-light7 align-baseline">
            <div>
              <h4 className="text-base font-medium text-gray m-0">
                Filters Results:
              </h4>
            </div>

            <button
              type="button"
              className="text-sm hover:text-gray-dark"
              onClick={clearAllFilters}
            >
              Clear Filters
            </button>
          </div>
        ) : null}
        <div
          className={classNames(
            'text-sm font-bold items-start flex flex-row flex-wrap justify-start',
            {
              'min-h-[20px] pt-2': selectedFiltersList.length > 0,
            }
          )}
        >
          {selectedFiltersList.length > 0
            ? selectedFiltersList.map(filter => (
                <SelectedFilter
                  filterName={filter.filterName}
                  onDeselect={handleRemoveFilter}
                  name={filter.name}
                  type={filter.type}
                />
              ))
            : null}
        </div>
        <div>
          <div>
            {MATERIAL_CODES && (
              <>
                <h3 className="text-base">Materials</h3>
                <div className="mb-4 text-base font-normal max-lg:overflow-x-hidden">
                  <div className="space-y-2">
                    {MATERIAL_CODES.map(material => {
                      const key = Object.keys(material)[0];
                      return (
                        <Checkbox
                          key={key}
                          onClick={() => onMaterialChange(key)}
                          checked={!!isMaterialFiltered[key]}
                          id={key.id}
                          size="sm"
                        >
                          {material[key]}
                        </Checkbox>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export const ColorFilter = connectStateResults(
  ({ searchResults, onColorChange, colorFiltered }) => {
    if (!searchResults) {
      return null;
    }
    return (
      <div className="flex flex-row items-center mr-[25px]">
        <ul className="list-none flex flex-row m-0">
          {COLOR_CODES_FABRIC.map(colorValue => {
            const key = Object.keys(colorValue)[0];
            if (key === 'beige/white') return null;
            if (key === 'purple') return null;

            const isColorActive =
              colorFiltered.currentColor.indexOf(
                `${key[0].toUpperCase()}${key.substr(1)}`
              ) > -1;

            return (
              <li
                key={key}
                onClick={() => {
                  onColorChange(`${key[0].toUpperCase()}${key.substr(1)}`);
                }}
                className={classNames(
                  'flex items-center justify-center cursor-pointer px-1 py-0',
                  'last:pr-0',
                  '[&_span]:w-5 [&_span]:h-5 [&_span]:rounded-full [&_span]:inline-block [&_span]:[background-position:50%]',
                  {
                    'border-2 border-solid border-gray': isColorActive,
                  }
                )}
              >
                <span
                  className={classNames({
                    'border border-solid border-gray':
                      key === 'white' || isColorActive,
                  })}
                  style={{
                    background: colorValue[key],
                  }}
                />

                <JBImage
                  className={classNames('absolute', {
                    'opacity-0': !isColorActive,
                    'opacity-100': isColorActive,
                  })}
                  src={checkMarkWhiteIcon}
                  width={10}
                  height={10}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);

const sortDescending = (a, b) => {
  if (a.count < b.count) return 1;
  if (b.count < a.count) return -1;
  return 0;
};

/*
 *  Mobile Search Dropdown List
 */
export const MobileSearchOptions = connectStateResults(({ searchResults }) => {
  if (!searchResults) {
    return null;
  }
  let productsFacet = [];
  let materialsFacet = [];

  productsFacet = searchResults.getFacetValues('type', {
    sortBy(a, b) {
      return sortDescending(a, b);
    },
  });
  materialsFacet = searchResults.getFacetValues('option_name', {
    sortBy(a, b) {
      return sortDescending(a, b);
    },
  });

  // Show only 3 highest results
  if (productsFacet.length > 3) {
    productsFacet.splice(3);
  }
  if (materialsFacet.length > 3) {
    materialsFacet.splice(3);
  }

  return (
    <div className="[&_ul]:list-none [&_ul_a]:no-underline [&_ul_a_li]:px-8 [&_ul_a_li]:py-4 [&_ul_a_li]:bg-gray-light8 [&_ul_a_li]:[border-bottom:1px_solid_#dbdbdb] [&_ul_a_li]:text-[#252729] [&_ul_a_li]:text-center [&_ul_a_li]:text-[.8rem] [&_ul_a:first-child_li]:first:[border-top:1px_solid_#dbdbdb] [&_ul_a:only-child_li]:only:[border-top:0]">
      {searchResults.query.length > 0 ? (
        <ul>
          {productsFacet.map(facet => (
            <Link
              key={facet.name}
              to={getAdvancedSearchUrlForQueryAndIndex(
                searchResults.query,
                'products',
                `type:${facet.name}`
              )}
            >
              <li>{`${searchResults.query} in ${facet.name}`}</li>
            </Link>
          ))}
          {materialsFacet.length > 0
            ? materialsFacet.map(facet => (
                <Link
                  key={facet.name}
                  to={getAdvancedSearchUrlForQueryAndIndex(
                    searchResults.query,
                    'products',
                    `option_name:${facet.name}`
                  )}
                >
                  <li>{`${searchResults.query} in ${facet.name}`}</li>
                </Link>
              ))
            : null}
        </ul>
      ) : null}
    </div>
  );
});
